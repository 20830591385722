
const fall = {
  name: 'Fall',
  slug: 'fall',
  events: [
    {
      name: 'Virtuous Veterans Day',
      slug: 'virtous-veterans-day',
      description: 'Celebrate the service of veterans by pitching in yourself to improve the world. Volunteer or donate in honor of a veteran you know.',
      imagePath: 'virtuous-veterans.png',
    },
    {
      name: 'Charitable Columbus Day',
      slug: 'charitable-columbus-day',
      description: 'Set a course toward giving, and discover the joy of helping others with a range of options for doing good.',
      imagePath: 'chariatble-colombus.png',
    },
    {
      name: 'Big-hearted Halloween',
      slug: 'big-hearted-halloween',
      description: 'Have no fear: being giving will make your life better. Hallow the lives of the departed by doing good works in their memory.',
      imagePath: 'big-hearted-halloween.png',
    },
    {
      name: 'Benevolent Birthdays',
      slug: 'benevolent-birthdays',
      description: "Ask friends and family to donate to your favorite nonprofit rather than give you things you don't need that will clutter your life.",
      imagePath:  'benevolent-birthdays.png',
    },
    {
      name: 'Giving at Thanksgiving',
      slug: 'giving-at-thanksgiving',
      description: "As thanks for what you've received, consume less this week or month and donate the proceeds to charity.",
      imagePath:  'giving-at-thanksgiving.png',
    },
    {
      name: 'Bake For Good',
      slug: 'bake-for-good',
      description: 'Put your baking prowess to good use by with a bake sale to raise money for a worthy cause.',
      imagePath: 'bake-for-good.png',
    },
  ] ,
}

const winter = {
  name: 'Winter',
  slug: 'winter',
  events: [
    {
      name: 'Helpful Hanukkuh',
      slug: 'helpful-hanukkuh',
      description: 'Celebrate the Feast of Dedication by dedicating yourself to more goodness and charity, and being a light to the world.',
      imagePath: 'helpful-hanukkah.png',
    },
    {
      name: 'Christmas Compassion',
      slug: 'christmas-compassion',
      description: 'Celebrate Christmas by bringing more kindness into the world through giving your time and money to help others.',
      imagePath: 'christmas-compassion.png',
    },
    // {
    //   name: 'Holiday Charity',
    //   slug: 'holiday-charity',
    //   description: 'Give and get fewer gifts and donate the difference to charity, volunteer your time or choose a charity as a family to donate to for the holidays.',
    //   imagePath: 'holiday-charity.png,
    // },
    {
      name: 'Righteous Resolutions',
      slug: 'righteous-resolutions',
      description: 'Make resolutions to donate or volunteer in the year to come. Or make resolutions to reduce bad habits and donate the saved money/time to nonprofits.',
      imagePath: 'righteous-resolutions.png',
    },
    {
      name: 'Noble New Year',
      slug: 'noble-new-year',
      description: "Spend less on drinking on New Year's eve and donate the money to charity. Or spend time volunteering to end the year, or to kick off the new year.",
      imagePath: 'noble-new-year.png',
    },
    {
      name: 'MLK Day Kindness',
      slug: 'mlk-day-kindness',
      description: 'Celebrate the life and contributions of MLK by volunteering or donating to nonprofits to make the world a better place for all people.',
      imagePath: 'mlk-day-kindness.png',
    },
    {
      name: 'Virtous Valentines',
      slug: 'virtuous-valentines',
      description: "Share your love with the world by volunteering on Valentine's day, or by spending less on each other and donating the difference to good causes.",
      imagePath: 'virtous-valentines.png',
    },
    {
      name: 'Polar Plunge For Good',
      slug: 'polar-plunge-for-good',
      description: 'Jump in a really cold lake or the ocean in the winter to raise money for your favorite charity.',
      imagePath:  'polar-plunge.png',
    },
    {
      name: 'Purposeful Presidents Day',
      slug: 'purposeful-presidents-day',
      description: 'Be the president of your own life, and choose from a range of ways to make it more giving.',
      imagePath: 'purposeful-presidents-day.png',
    },
    {
      name: 'Lent for Love',
      slug: 'lent-for-love',
      description: 'Tap into our app to do the three practices of lent: giving something up, donating to charity and praying. Have beautiful prayers emailed to your inbox daily or weekly.',
      imagePath: 'lent-for-love.png',
    },
  ],
}

const spring = {
  name: "Spring",
  slug: 'spring',
  events: [
    {
      name: 'St. Patrick\'s Sharing',
      slug: 'st-patricks-sharing',
      description: 'Mimic the spirit, imagination and kindness of the Irish by sharing your time, your heart and your gold with the world.',
      imagePath: 'st-patricks-sharing.png',
    },
    {
      name: 'Fools For Gold',
      slug: 'fools-for-good',
      description: "Play practical jokes and pranks on your friends or the general public on April Fools Day to raise awareness and money for your favorite causes.",
      imagePath: "april-for-autism.png",
    },
    {
      name: 'Easter Giving',
      slug: 'easter-giving',
      description: 'Put your faith into motion during the Easter season by helping the people Jesus repeatedly told people to help: the hungry and the poor.',
      imagePath: 'good-with-easter-giving.png',
    },
    {
      name: 'Grow One For Good',
      slug: 'grow-one-for-good',
      description: 'Support your team and discover how you look with a beard by growing one during the hockey playoffs to raise awareness and money for your favorite cause.',
      imagePath: 'grow-one-for-good.png',
    },
    {
      name: 'Mothers for Good',
      slug: 'mothers-for-good',
      description: "Moms: ask your children to make a donation to your favorite charity in your honor, rather than giving you flowers that will die and gifts you don't need that will clutter your life.",
      imagePath: 'mothers-for-good.png',
    },
    {
      name: 'Run For Good',
      slug: 'run-for-good',
      description: 'Organize a road race to raise money for charity, or do a solo or small group run of your own to raise money and raise awareness.',
      imagePath: 'run-for-good.png',
    },
  ]
}

const summer = {
  name: "Summer",
  slug: 'summer',
  events: [
    {
      name: 'Memorial Day Donating',
      slug: 'memorial-day-donating',
      description: 'Honor those who sacrificed everything to help the world by making some sacrifice of your own to improve the world.',
      imagePath: 'memorial-day-donating.png',
    },
    {
      name: 'Altruistic Anniversaries',
      slug: 'altruistic-anniversaries',
      description: 'Pick a volunteer activity to do together as part of celebrating your anniversary, or make a plan to donate more in the coming year.',
      imagePath: 'altruistic-anniversaries.png',
    },
    {
      name: 'Fathers For Good',
      slug: 'fathers-for-good',
      description: "Dads: ask your children to make a donation to your favorite charity in your honor, instead of giving you another tie you're never going to wear.",
      imagePath: 'fathers-for-good.png'
    },
    {
      name: 'Feel-Good Fourth',
      slug: 'feel-good-fourth',
      description: "Host a July 4th cook-out or pool party with a suggested donation that goes to a good cause.",
      imagePath: 'feel-good-fourth.png'
    },
    // {
    //   name: '4th for Good',
    //   slug: 'fourth-for-good',
    //   description: "Host a July 4th cook-out or pool party with a suggested donation that goes to a good cause.",
    //   imagePath: '4th-for-good.pn)
    // },
    // {
    //   name: 'Walk For Good',
    //   slug: 'walk-for-good',
    //   description: "	Do a group walk or personal walk to raise money for a charity. You have the flexibility to do a walk anywhere in the world any day of the year.",
    //   imagePath: 'walk-for-good.pn)
    // },
    {
      name: 'Virtuous Vacations',
      slug: 'virtuous-vacations',
      description: "Spend less than normal on your family or personal vacation and donate the difference to your favorite charity.",
      imagePath: 'virtuous-vacations.png'
    },
    {
      name: 'Labor day Love',
      slug: 'labor-day-love',
      description: 'Honor people who are treated unfairly at work, who are too ill to work or who are not compensated adequately for their labor by donating to a good cause.',
      imagePath: 'labor-day-love.png'
    },
    // {
    //   name: 'Ride For Good',
    //   slug: 'ride-for-good',
    //   description: 'Organize a group ride or do an individual ride of your own to raise money for a great cause. You can do this anywhere anytime.',
    //   imagePath:  'ride-for-good.png)
    // },
  ]
}

const state = {
  data: [
    fall,
    winter,
    spring,
    summer,
  ]
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}